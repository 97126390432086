<template>
  <div class="bill-print" ref="billitem">
    <div class="bill-main">
      <h6 class="bill-main--title">欢迎光临{{printInfos.shopName}}</h6>
      <ul class="bill-info">
        <li class="bill-info__item">
          <span class="bill-info__item-title">交易编号:</span>{{printInfos.orderId}}
        </li>
        <li class="bill-info__item">
          <span class="bill-info__item-title">交易时间:</span>{{printInfos.createTime}}
        </li>
      </ul>
      <div class="bill-goods__list line">
        <table>
          <tr>
            <th>商品名称</th>
            <th>数量</th>
            <th>单价</th>
            <th>小计</th>
          </tr>
          <tr>
            <td>
              <div style="width: 100px;" class="text-ellipsis">{{printInfos.kartClassifyName}}</div>
            </td>
            <td>x{{printInfos.itemCount}}</td>
            <td>{{printInfos.price}}</td>
            <td>{{printInfos.itemCount * printInfos.price}}</td>
          </tr>
        </table>
        <ul class="bill-info line">
          <li class="bill-info__item">
            <span class="bill-info__item-title">合计: </span>{{printInfos.amount}}RM
          </li>
          <li class="bill-info__item">
            <span class="bill-info__item-title">优惠: </span>{{printInfos.amountDiscount}}RM
          </li>
          <li class="bill-info__item">
            <span class="bill-info__item-title">实收{{printInfos.payMethodName ? '(' + printInfos.payMethodName + ')': ''}}: </span>{{printInfos.amountPaid}}RM
          </li>
        </ul>
        <div class="bill-info__tips line">
          <div>谢谢惠顾，欢迎下次光临!</div>
<!--          <div>此小票一个月内开具发票有效！</div>-->
        </div>
      </div>
    </div>
    <div v-for="item in printInfos.printInfoList" :key="item.orderKartPlayId">
      <div class="page-break"></div>
      <div class="bill-sub">
        <div class="bill-queue">
          <div>值车凭证</div>
          <div>{{item.playSn}}</div>
        </div>
        <div class="line">
          <div class="bill-sub__item">
            <div>组别: {{item.name}}</div>
          </div>
          <div class="bill-sub__item">
            <div>场次: {{item.matchName}}</div>
          </div>
          <div class="bill-sub__item">
            <div>车号: {{item.carName}}</div>
          </div>
        </div>
        <div class="bill-queue">
          <div class="bill-qr">
            <img class="bill-qr-img" :src="item.qrCode" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintJs from '@/libs/print';
import {
  wxUrl,
} from '@/libs/config';
import QRCode from 'qrcode';
import { getLocal } from '../../libs/utils';
import { queryPrintInfo } from '@/api/preMatch';

export default {
  name: 'DiyOrderBill',
  components: {},
  props: {},
  data() {
    return {
      data: {
        storeInfo: {},
        queueCount: 0
      },
      printInfoList: [],
      printInfos: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    this.storeInfo = getLocal('storeInfo');
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    handlePrintBill(orderId) {
      queryPrintInfo({ orderId }).then((result) => {
        if (result) {
          this.printInfos = result;
          console.log(result);
          // this.printInfoList = result;
          // this.goodsInfo.shopName = result[0].shopName;
          // this.goodsInfo.orderId = result[0].orderId;
          // this.goodsInfo.createTime = result[0].createTime;
          // this.goodsInfo.amount = result[0].amount;
          // this.goodsInfo.amountDiscount = result[0].amountDiscount;
          // this.goodsInfo.amountPaid = result[0].amountPaid;
          // this.goodsInfo.price = result[0].price;
          // this.goodsInfo.itemCount = result[0].itemCount;
          // this.goodsInfo.kartClassifyName = result[0].kartClassifyName;

          this.generateQRCode();
          this.$nextTick(() => {
            PrintJs(this.$refs.billitem);
          });
        }
      });
    },
    generateQRCode() {
      const self = this;
      const storeInfo = getLocal('storeInfo');
      const isWeAppShop = storeInfo.wxMiniAppFlag;

      this.printInfos.printInfoList.forEach((item, index) => {
        let codeData = wxUrl + item.orderKartPlayId;
        // 需要跳转到小程序到门店
        if (isWeAppShop) {
          const query = `?shopId=${storeInfo.shopId}&shopName=${storeInfo.shopName}&playCode=`;
          codeData = storeInfo.wxAppPath + query + item.orderKartPlayId;
          console.log(codeData);
        }

        QRCode.toDataURL(codeData, function (error, data) {
          if (error) {
            this.$message.error(error.toString);
            throw error;
          }
          item.qrCode = data;
          self.$set(self.printInfos.printInfoList, index, item);
        });
      });
    }
  },
};
</script>

<style lang="less" scoped>

.bill-print {
  display: none;
}
@media print {
  .bill-print {
    display: block;
  }
}

@page {
  /* 纵向打印 */
  size: portrait;
  margin: 0;
  padding: 1px;
  margin-left: 0cm;
  margin-bottom: 5cm;
}

.page-break {
  page-break-after: always;
}

.bill-print {
  font-size: 12px;
}
.bill-info {
  color: #000;
  list-style: none;
}
.bill-info__item {
}
.bill-info__item-title {
  word-break: break-word;
  width: 200px;
}

.line:before {
  content: '';
  display: block;
  margin-top: 10px;
  border: 1px dashed #000;
}
.bill-queue {
  width: 200px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}
.bill-qr {
  margin-top: 10px;
  text-align: center;
}

.bill-qr-img {
  width: 200px;
  height: 200px;
}
.bill-sub__item {
  display: flex;
  justify-content: space-between;
}
.bill-main,
.bill-main--title,
table,
table th,
table td,
.bill-sub,
.bill-info__tips {
  font-size: 12px;
  color: #000;
}
</style>
